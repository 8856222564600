<template>
  <div>
    <form @submit.prevent="onSubmit">
      <vx-card title="Form Add">

        <div class="vx-row mb-6">

          <div class="vx-col sm:w-4/12 w-full">
            <label class="vs-input--label">{{$i18n.t('PODate')}}</label>
            <datepicker format="d/MM/yyyy" :value="form.date" @input="form.date = $moment($event)"></datepicker>
          </div>

          <div class="vx-col sm:w-4/12 w-full">
            <vs-input
              class="w-full"
              type="text"
              :label="$i18n.t('PONumber')"
              v-model="form.po_number"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
            />
          </div>

          <div class="vx-col sm:w-4/12 w-full">
            <small class="mb-1">{{ $i18n.t('CustomerName') }}</small>
            <v-select :clearable="false" v-model="form.customer" label="name" :options="customerOptions"></v-select>
          </div>

        </div>

        <div class="vx-row mb-6">
          <div class="vx-col sm:w-6/12 w-full">
            <vs-input
              class="w-full"
              type="text"
              :label="$i18n.t('ProjectName')"
              v-model="form.project_name"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
            />
          </div>
          <div class="vx-col sm:w-6/12 w-full">
            <vs-input
              class="w-full"
              type="text"
              :label="$i18n.t('PrincipleName')"
              v-model="form.principle_name"
              val-icon-danger="icon-x"
              val-icon-pack="feather"
            />
          </div>

        </div>

        <div class="mb-6">

          <vx-card :title="$i18n.t('Spesification')" style="border: 1px solid #ccc;">

            <template slot="actions">
              <vs-button style="margin: 0 auto;" color="primary" size="small" icon-pack="feather" icon="icon-plus" class="mr-4" @click="() => { popupSpesifications = true }">
                {{ $i18n.t('AddSpesification') }}
              </vs-button>
            </template>

            <vs-table :data="form.specifications">

              <template slot="thead">
                <vs-th>{{$i18n.t('Name')}}</vs-th>
                <vs-th class="text-center">{{$i18n.t('Qty')}}</vs-th>
                <vs-th class="text-right">{{$i18n.t('Price')}}</vs-th>
                <vs-th class="text-right">{{$i18n.t('Total')}}</vs-th>
                <vs-th class="text-center">Action</vs-th>
              </template>

              <template slot-scope="{data}">
                <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                  <vs-td :data="data[indextr].name">
                    {{ data[indextr].name }}
                  </vs-td>

                  <vs-td :data="data[indextr].qty">
                    {{ data[indextr].qty }}
                  </vs-td>

                  <vs-td :data="data[indextr].price">
                    {{ currencies[data[indextr].currency-1].text }} {{ formatNumber(data[indextr].price) }}
                  </vs-td>

                  <vs-td :data="data[indextr].total">
                    {{ currencies[data[indextr].currency-1].text }} {{ formatNumber(parseInt(data[indextr].price) * parseInt(data[indextr].qty)) }}
                  </vs-td>

                  <vs-td>
                    <feather-icon icon="Trash2Icon" svgClasses="h-5 w-5 mr-4 hover:text-danger cursor-pointer" @click="data.splice(indextr, 1)" />
                    <feather-icon icon="EditIcon" svgClasses="h-5 w-5 mr hover:text-danger cursor-pointer" @click="editSpesification(data[indextr])" />
                  </vs-td>

                </vs-tr>
              </template>

            </vs-table>

            <vs-popup classContent="popup-example" :title="$i18n.t('Spesification')" :active.sync="popupSpesifications">
              <div class="vx-row mb-6">
                <div class="vx-col md:w-1/1 w-full">
                   <vs-input class="w-full mb-3" :label="$i18n.t('Name')" v-model="handle.specification.name" />
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col md:w-1/3 w-full">
                  <vs-input-number min="1" v-model="handle.specification.qty" label="qty:"/>
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col md:w-1/4 w-full">
                  <small class="mb-1">{{ $i18n.t('Currency') }}</small>
                  <v-select :clearable="false" v-model="handle.specification.currency" :reduce="item => item.value" :options="currencies" label="text"></v-select>
                </div>
                <div class="vx-col md:w-3/4 w-full">
                  <vs-input type="number" class="w-full mb-3" :label="$i18n.t('Price')" v-model="handle.specification.price" />
                </div>
              </div>
              <div class="vx-row mb-6">
                <div class="vx-col md:w-1/1 w-full">
                  <vs-input type="number" class="w-full mb-3" label="Total" :value="parseInt(handle.specification.price) * parseInt(handle.specification.qty)" readonly=""/>
                </div>
              </div>
              <vs-button color="primary" type="filled" @click="addSpecification" v-if="!isSelected">Add</vs-button>
              <vs-button color="primary" type="filled" @click="updateSpecification" v-else>Update</vs-button>
            </vs-popup>

          </vx-card>

        </div>

        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full">
            <div class="flex">
              <vs-button class="mr-3 mb-2" icon-pack="feather" icon="icon-save" @click.prevent="onSubmit">{{ $i18n.t('Save') }}</vs-button>
              <vs-button type="flat" color="primary" class="mb-2" @click.prevent="$router.push({ name: 'purchase-to-principle' })">{{ $i18n.t('Cancel') }}</vs-button>
            </div>
          </div>
        </div>

      </vx-card>
    </form>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import Datepicker from 'vuejs-datepicker'
import axios from 'axios';
import recipe from "@/recipe";

export default {
  name: "QuotationForm",
  data: () => ({
    // options
    customerOptions: [],

    isSelected: false,
    popupSpesifications: false,
    form: {
      specifications: [
      ],
      customer: false,
    },
    handle: {
      specification: {
        name: '',
        qty: 1,
        price: 0,
        currency: ''
      }
    },
    currencies: [
      {
        text: 'USD',
        value: '1'
      },
      {
        text: 'EUR',
        value: '2'
      },
      {
        text: 'IDR',
        value: '3'
      }
    ],
    error: {}
  }),
  methods: {
    getOptions () {
      axios.get('/customer').then(resp => {
        this.customerOptions = resp.data;
        console.log(resp.data);
      });
    },
    addSpecification () {
      this.handle.specification.total = this.handle.specification.qty * this.handle.specification.price;
      this.form.specifications.unshift(this.handle.specification);

      this.handle.specification = {
        name: '',
        qty: 1,
        price: 0,
        currency: 1
      };

      this.popupSpesifications = false;
      this.isSelected = false;
    },
    updateSpecification () {
      this.popupSpesifications = false;
    },
    editSpesification (item) {
      this.isSelected = item;
      this.handle.specification = item;
      this.handle.specification.currency = this.currencies.find(currency => currency.value == this.handle.specification.currency);

      this.popupSpesifications = true;
    },
    onSubmit () {
      let type = `${recipe}/api/v1/purchase-principles/`, payload = { body: this.form };
      if (this.id) {
        payload.id = this.id;
        type = `${recipe}/api/v1/purchase-principles/${payload.id}`;
        payload.body._method = 'PUT';
      }

      if (this.form.customer) {
        payload.body.customer_id = this.form.customer.id;
      }

      axios.post(type, payload.body).then((response) => {
        this.$swal({
          title: 'Successful',
          text: response.data.message,
          icon: 'success'
        }).then(() => {
          if (payload.id) {
            this.$router.push({name: 'purchase-to-principle-show', params: { id: this.$route.params.id }});
          } else {
            this.$router.push({name: 'purchase-to-principle'});
          }
        });
      }).catch(err => {

        // set error
        if (err.data.errors) {
          this.error = err.data.errors;
        }

        // show message error
        this.$vs.notify({
          title:'Opps something error',
          text: err.data.message,
          color:'danger'
        })

      });
    },
    total (data) {
      let currencies = this.currencies
        .map(currency => {
          currency.total = data.filter(item => item.currency == currency.value).map(item => parseInt(item.total));
          return currency;
        })
        .filter(currency => currency.total.length > 0)
        .map(currency => {
          currency.total = currency.total.reduce((total, item) => total += item);
          return `${currency.text} ${this.formatNumber(currency.total)}`;
        });

      return currencies.join(' + ');
    },
    formatNumber (value) {
      let val = (value/1).toFixed(0).replace('.', ',');
      let result = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");

      return result;
    }
  },
  computed: {
    ...mapGetters({
      'item': 'purchase/showItem'
    })
  },
  components: {
    Datepicker,
  },
  created() {
    this.getOptions();
    if (this.$route.params.id) {
      this.id = this.$secure.decrypt(this.$route.params.id);
      axios.get(`/purchase-principles/${this.id}`).then((response) => {
        console.log(response);
        this.form = response.data.values;
        this.form.customer = this.customerOptions.find(customer => customer.id == this.form.customer_id);
      });
    }
  }
}
</script>

<style scoped>

</style>
